<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Apertura de Propuesta</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons 
          :tenderId="this.tenderId"
          :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
                    <v-tab href="#vigilanciaOficio">
                      Oficio vigilancia
                    </v-tab>
                    <v-tab-item value="vigilanciaOficio">
                      <v-form ref="formOficioVigilancia" class="mt-10" @submit.prevent="submitFormOficioVigilancia">
                        <v-row class="mb-4">
                          <v-expansion-panels inset>
                            <v-expansion-panel
                            >
                              <v-expansion-panel-header>Número de Oficio</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col cols="12">
                                  <h1 class="mt-4 mx-2 ">Número de Oficio</h1>
                                  <trumbowyg
                                      v-model="data.numero_oficio"
                                      name="body"
                                      :config="configs.advanced"
                                      placeholder="Número de Oficio"
                                      class="form-control"
                                  ></trumbowyg>
                                </v-col>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                            >
                              <v-expansion-panel-header>Hora y Minutos</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col cols="12">
                                  <h1 class="mt-4 mx-2 ">Hora y Minutos</h1>
                                  <trumbowyg
                                      v-model="data.hora_minutos"
                                      name="body"
                                      :config="configs.advanced"
                                      placeholder="Hora y Minutos"
                                      class="form-control"
                                  ></trumbowyg>
                                </v-col>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                            >
                              <v-expansion-panel-header>Fecha en Letra</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col cols="12">
                                  <h1 class="mt-4 mx-2 ">Fecha en Letra
                                  </h1>
                                  <trumbowyg
                                      v-model="data.fecha_letra"
                                      name="body"
                                      :config="configs.advanced"
                                      placeholder="Fecha en Letra"
                                      class="form-control"
                                  ></trumbowyg>
                                </v-col>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-col cols="6" class="mt-4">
                            <v-btn type="submit" color="primary" style="width: 200px">
                              Guardar
                            </v-btn>
                          </v-col>
                          <v-col cols="6" class="mt-6">
                            <v-btn @click="generateDataSU" v-if="idSurveillance" color="primary" style="width: 400px">
                              Generar Archivo
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row class="mt-5">
                          <v-col cols="3">
                            <v-btn type="button" v-if="documentGenerateStatusSurveillance" @click="downloadFileServerSU"
                                   color="primary"
                                   style="width: 200px">
                              Descargar archivo
                            </v-btn>
                          </v-col>
                          <v-col cols="12" v-if="documentGenerateStatusSurveillance">
                            <h1 class="mt-4 mx-2 ">Visualización de documento
                            </h1>
                            <trumbowyg
                                v-model="documentGenerateSurveillance"
                                disabled="disabled"
                                name="body"
                                :config="configs.advanced"
                                placeholder="Visualización de documento"
                                class="form-control"
                            ></trumbowyg>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>
          <v-tab href="#propuestas" @click="findOneOpen">
           Propuestas
          </v-tab>
          <v-tab-item value="propuestas" >
            <v-form ref="form" class="mt-10" @submit.prevent="submitFormProposals">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Descripcion(parrafo 1)</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Descripcion(parrafo 1)</h1>
                        <trumbowyg
                            v-model="dataProposals.descripcion"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Descripcion(parrafo 1)"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Número de Participantes</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Número de Participantes</h1>
                        <trumbowyg
                            v-model="dataProposals.numero_participantes"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Número de Participantes"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Propuestas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Propuestas
                        </h1>
                        <trumbowyg
                            v-model="dataProposals.licitantes"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Propuestas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Horas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Horas
                        </h1>
                        <trumbowyg
                            v-model="dataProposals.minutos"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Horas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
                <v-col cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn type="button" v-if="idProposel" @click="generateData" color="primary" style="width: 200px">
                    Generar archivo
                  </v-btn>
                </v-col>
                <v-row class="mt-5">

                  <v-col cols="3">
                    <v-btn type="button" v-if="documentGenerateStatus" @click="downloadFileServer" color="primary" style="width: 200px">
                      Descargar archivo
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="documentGenerateStatus">
                    <h1 class="mt-4 mx-2 ">Visualización de documento
                    </h1>
                    <trumbowyg
                        v-model="documentGenerate"
                        disabled="disabled"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Visualización de documento"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#propuestasDesierto" @click="findOneOpenDesert">
            Propuestas Desierto
          </v-tab>
          <v-tab-item value="propuestasDesierto" >
            <v-form ref="form" class="mt-10" @submit.prevent="submitFormDesert">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Descripcion(parrafo 1)</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Descripcion(parrafo 1)</h1>
                        <trumbowyg
                            v-model="dataDesert.descripcion"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Descripcion(parrafo 1)"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Descripcion Proveedores o no proveedores</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Descripcion Proveedores o no proveedores</h1>
                        <trumbowyg
                            v-model="dataDesert.descripcion_proveedores"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Descripcion Proveedores o no proveedores"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Horas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Horas
                        </h1>
                        <trumbowyg
                            v-model="dataDesert.horas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Horas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
                <v-col cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn type="button" v-if="idDesert" @click="generateDataDesert" color="primary" style="width: 200px">
                    Generar archivo
                  </v-btn>
                </v-col>
                <v-row class="mt-5">

                  <v-col cols="3">
                    <v-btn type="button" v-if="documentGenerateStatusDesert" @click="downloadFileServerDesert" color="primary" style="width: 200px">
                      Descargar archivo
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="documentGenerateStatusDesert">
                    <h1 class="mt-4 mx-2 ">Visualización de documento
                    </h1>
                    <trumbowyg
                        v-model="documentGenerateDesert"
                        disabled="disabled"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Visualización de documento"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-tab-item>


        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'

export default {
  name: "DetailOpeningProposals",
  data() {
    return {
      overlay: false,
      tenderId: null,
      externalId: null,
      idSurveillance: null,
      documentComplete:null,
      documentGenerate:null,
      documentGenerateDesert:null,
      documentGenerateStatus:false,
      documentGenerateStatusDesert:false,
      documentGenerateSurveillance: null,
      documentGenerateStatusSurveillance: false,
      id: null,
      formatosId:null,
      idProposel:null,
      idDesert:null,
      dataDesert: {
        horas: "",
        descripcion_proveedores: '',
        descripcion: '',
        formatosId: 32,
        tenderId: null,
      },
      dataProposals: {
        numero_participantes: "",
        licitantes: '',
        minutos: '',
        descripcion: '',
        formatosId: 31,
        tenderId: null,
      },

      data: {
        hora_minutos: "",
        fecha_letra: "",
        numero_oficio: "",
        formatosId: 25,
        tenderId: null,
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    CheckButtons,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('openingProposals', ['findOneOpeningProposals', 'findOneOpeningProposals','generateHtmlFormat','downloadFile','findOneOpeningProposalsTender','updateOpeningProposals','createOpeningProposals']),
    ...mapActions('openingDesert', ['findOneOpeningDesert', 'findOneOpeningDesert','generateHtmlFormatOpeningDesert','downloadFileOpeningDesert','findOneOpeningDesertTender','updateOpeningDesert','createOpeningDesert']),
    ...mapActions('ja_surveillance_unit', ['updateJASurveillanceUnit', 'findOneJASurveillanceUnit', 'generateHtmlFormatSU', 'downloadFileSU', 'createJASurveillanceUnit']),
    ...mapActions('tenders', ['findOneTenders']),
    async submitFormProposals() {

      this.overlay = true;
      if (this.idProposel) {
        const response = await this.updateOpeningProposals({id: this.idProposel, data: this.dataProposals});
        if (!response.error) {
          this.sweetAlertSuccess('Se Edito correctamente')
          await this.findOneOpen();
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createOpeningProposals(this.dataProposals);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOneOpen();
        } else {
          this.sweetAlertError('Error al crear')
        }

      }

      this.overlay = false;
    },
    async submitFormDesert() {

      this.overlay = true;
      if (this.idDesert) {
        const response = await this.updateOpeningDesert({id: this.idDesert, data: this.dataDesert});
        if (!response.error) {
          this.sweetAlertSuccess('Se Edito correctamente')
          await this.findOneOpenDesert();
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createOpeningDesert(this.dataDesert);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOneOpenDesert();
        } else {
          this.sweetAlertError('Error al crear')
        }

      }

      this.overlay = false;
    },
    async findOneOpenDesert() {
      const response = await this.findOneOpeningDesertTender(this.tenderId);

      if (response) {
        this.idDesert = response.id
        this.dataDesert.formatData = response.formatoData;
        this.dataDesert.horas = response.horas;
        this.dataDesert.descripcion_proveedores = response.descripcion_proveedores;
        this.dataDesert.tenderId = response.licitacionesId;
        this.dataDesert.descripcion = response.descripcion;
      } else {
        this.dataDesert.tenderId = this.tenderId;

      }
    },
    async findOneOpen() {
      const response = await this.findOneOpeningProposalsTender(this.tenderId);

      if (response) {
        this.idProposel = response.id
        this.dataProposals.formatData = response.formatoData;
        this.dataProposals.numero_participantes = response.numero_participantes;
        this.dataProposals.licitantes = response.licitantes;
        this.dataProposals.tenderId = response.licitacionesId;
        this.dataProposals.minutos = response.minutos;
        this.dataProposals.descripcion = response.descripcion;
      } else {
        this.dataProposals.tenderId = this.tenderId;

      }
    },
    async findMarketOne() {
      const response = await this.findOneJASurveillanceUnit( this.tenderId);
      if (response) {
        this.idSurveillance = response.id
        this.documentComplete = response.documentoCompleto
        this.data.hora_minutos = response.hora_minutos;
        this.data.fecha_letra = response.fecha_letra;
        this.data.numero_oficio = response.numero_oficio;
        this.data.tenderId = response.licitacionesId;

        this.data.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal){
          this.externalId = responseExternal.claveLicitacion;
        }else{
          this.externalId =null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequiredOficioVigilancia() {
      if (!this.data.numero_oficio) {
        this.sweetAlertWarning('Campo Número de Oficio es necesario');
        return false
      }
      if (!this.data.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.data.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
    },
    async submitFormOficioVigilancia() {
      const validateData = await this.validateRequiredOficioVigilancia();

      if (!validateData) {
        return
      }
      this.overlay = true;
      if (this.idSurveillance) {
        const response = await this.updateJASurveillanceUnit({id: this.idSurveillance, data: this.data});
        if (!response.error) {
          this.sweetAlertSuccess('Se edito correctamente')
          await this.findMarketOne()
        } else {
          this.sweetAlertError('Error al editar')
        }
      }
      this.overlay = false;
    },

    async generateDataSU() {
      this.overlay = true;
      const response = await this.generateHtmlFormatSU({id: this.idSurveillance});
      if (!response.error) {
        this.documentGenerateSurveillance = response;
        this.documentGenerateStatusSurveillance = true;
      } else {
        this.documentGenerateSurveillance = response;
        this.documentGenerateStatusSurveillance = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async generateDataDesert() {
      this.overlay = true;
      const response = await this.generateHtmlFormatOpeningDesert({id: this.idDesert});
      if (!response.error) {
        this.documentGenerateDesert = response;
        this.documentGenerateStatusDesert = true;
      } else {
        this.documentGenerateDesert = response;
        this.documentGenerateStatusDesert = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async generateData() {
      this.overlay = true;
      const response = await this.generateHtmlFormat({id: this.idProposel});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFile({id: this.id,externalId: this.externalId});
      this.overlay = false;
    },
    async downloadFileServerDesert() {
      this.overlay = true;
      await this.downloadFileOpeningDesert({id: this.idDesert, externalId: this.externalId});
      this.overlay = false;
    },
    async downloadFileServerSU() {
      this.overlay = true;
      await this.downloadFileSU({id: this.idSurveillance, externalId: this.externalId});
      this.overlay = false;
    },
    alertNexPrograming(){
      this.sweetAlertWarning('Proximamente')
    }
  },



}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
